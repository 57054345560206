<template>
  <div class="znzz">
    <div class="header">
      <div class="zhuti">
        <div class="h1">标识体系应用服务</div>
        <div class="h2">
          <p>统一规范的标识体系，提供全生命周期管理。</p>
          <p> 优化数据流通，助力企业高效管理与利用资源。</p>
        </div>
        <div style="margin-top: 30px">
          <el-button type="primary" @click="goadmin()">立即申领</el-button>
        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="titles" id="m2">快速了解</div>
      <div class="zhuti flex">
        <div style="width: 600px;height: 350px">
          <el-image :src="datas.ksljImage"></el-image>
        </div>
        <div class="flex-1">
          <div class="h1 titles" style="text-align: left;padding-left: 40px;padding-bottom: 30px;margin-top: 25px">{{datas.ksljSubtitle}}</div>
          <div class="h2" style="color: #666666;padding:0 40px 40px 40px;line-height: 25px">{{datas.ksljDescription}}</div>
        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="titles" id="m2">产品架构</div>
      <div class="zhuti">
        <div style="margin-bottom: 35px"><el-image :src="datas.cpjgImage"></el-image></div>
      </div>
    </div>
    <div class="bg">
      <div class="zhuti">
        <div class="titles" >应用场景</div>
        <div class="gn">
          <div class="gongnenglist"  :class="{'width600':index==3}" v-for="(li,index) in yycjList" :key="index">
            <div class="gnimage" :class="{'width600':index==3}"><el-image :src="li.image"></el-image></div>
            <div style="margin: 0 auto">
              <div class="gntitle" style="text-align: left;margin-left: 15px">{{li.title}}</div>
              <div style="padding: 0 12px">{{li.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="zhuti">
        <div class="titles" >核心功能</div>
        <div class="yycj">
          <div class="gongnenglist" v-for="(li,index) in hxgnList" :key="index">
            <div class="gnimage"><el-image :src="li.icon"></el-image></div>
            <div style="width: 288px;margin: 0 auto">
              <div class="gntitle">{{li.title}}</div>
              <div style="padding: 0 24px">{{li.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="titles" id="m2">使用流程</div>
      <div class="zhuti">
        <div><el-image :src="datas.sylcImage"></el-image></div>
      </div>
    </div>
    <div class="bg3">
      <div class="zhuti">
        <div class="titles" >相关推荐</div>
        <div class="xgtj">
          <div class="gongnenglist" v-for="(li,index) in xgtjList" :key="index">
            <div class="gnimage"><el-image :src="li.image"></el-image></div>
            <div>
              <div class="gntitle">{{li.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {lowcodeindex, productionmanagementindex, signapplyindex} from "../../request/moudle/znzz";
import Advice from "../common/advice.vue";
import MoreList from "../common/moreList.vue";
export default {
  name: "",
  components:{
    Advice,
    MoreList
  },
  data(){
    return  {
      datas:'',
      number:0,
      yycjList:[],
      hxgnList:[],
      xgtjList:[],
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    goadmin(){
      window.open(this.datas.bannerApply, '_blank')
    },
    gotabs(index){
      this.number=index
    },
    loadconfig(){
      this.$api.znzz.signapplyindex().then((res)=>{
        this.datas=res.data
        this.yycjList=res.data.yycjList
        this.hxgnList=res.data.hxgnList
        this.xgtjList=res.data.xgtjList
      })
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage display'
      }
      e.target.className='hdimage remove-class show'
    },

    //推荐
    tuijian(item){
      if(item.title == "商城管理"){
        window.open('http://shopcmer.bs-icloud.com:8000/login?redirect=%2Fdashboard','_blank')
      }else if(item.title == "仓库管理"){
        window.open('http://admin.czicloud-sy.cn:8000/#/bsLogin','_blank')
      }else if(item.title == "标识解析"){
        window.open('https://www.cz-icloud.com/trial','_blank')
      }else if(item.title == "设备管理"){
        // window.open(config.adminhref+'/#/myiframe/urlPath?','_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
}
.mag32{
  margin: 24px;
  width: 410px;
}
.gn{
  overflow: hidden;
  .gongnenglist{
    float: left;
    margin: 8px;
    border-radius: 8px;
    width: 380px;
    height: 340px;
    background: #fff;
    color: #999;
    .gntitle{
      text-align: center;
      font-size: 16px;
      font-weight: bolder;
      margin: 25px 0;
      color: #1D1D1D;
    }
    .gnimage{
      width: 380px;
      height: 170px;
    }
  }
}
.yycj{
  overflow: hidden;
  .gongnenglist{
    float: left;
    margin: 8px;
    border-radius: 8px;
    width: 280px;
    background: #fff;
    color: #999;
    .gntitle{
      text-align: center;
      font-size: 16px;
      font-weight: bolder;
      margin: 25px 0;
      color: #1D1D1D;
    }
    .gnimage{
      width: 60px;
      height: 60px;
      margin: 0 auto;
    }
  }
}
.xgtj{
  overflow: hidden;
  .gongnenglist{
    float: left;
    margin: 8px;
    border-radius: 8px;
    width: 380px;
    background: #fff;
    margin-bottom: 55px;
    color: #999;
    position: relative;
    .gntitle{
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: bolder;
      color: #fff;
      position: absolute;
      top: 76px;
    }
    .gnimage{
      width: 380px;
      height: 170px;
    }
  }
}
.szls{
  .anli{
    width: 1200px;
  }
  .anlileft{
    width: 440px;
    color: #fff;
  }
  .anlit{
    font-size: 20px;
    padding: 40px 0;
  }
  .anliimage{
    width: 660px;
    height: 300px;
    margin-left: 60px;
  }
}
.cj{
  overflow: hidden;
  margin-bottom: 56px;
  .cjpo{
    width: 600px;
    position: absolute;
    top: 30px;
  }
  .cjlist:nth-child(odd) .cjpo{
    right: 0;
  }
  .cjlist{
    width: 50%;
    height: 120px;
    float: left;
    position: relative;
  }
  .cjlist0{
    background: url("../../assets/znzz/cjlist0.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjlist1{
    background: url("../../assets/znzz/cjlist1.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjlist2{
    background: url("../../assets/znzz/cjlist2.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjlist3{
    background: url("../../assets/znzz/cjlist3.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjimage{
    width: 40px;
    height: 40px;
    margin: 15px 25px;
  }
  .name{
    font-size: 16px;
    color: #1D1D1D;
  }
  .desc{
    color: #999;
  }
}
.header{
  height: 230px;
  background: url("../../assets/banner/bssl.jpg") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  color: #313F6D;
  .h1{
    font-size: 42px;
    margin-top: 25px;
  }
  .h2{
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
}
.tabs{
  overflow: hidden;
  margin-bottom: 35px;
  .tablist{
    width: 160px;
    height: 160px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
  }
  .tabimage{
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 12px  auto;
  }
  .isactive{
    background: rgba(255,255,255,0.2);
  }
}
.more{
  overflow: hidden;
  padding: 56px;
  .mlist{
    text-align: center;
    float: left;
    width: 25%;
  }
  .minage{
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
.bg{
  background: #F4F9FE;
  padding:56px;
}
.bg2{
  background: url("../../assets/znzz/bg2.png") no-repeat;
  background-size: 100% 100%;
  padding: 56px 0;
}
.bg3{
  .titles{
    padding: 56px 0;
  }
}
.bg4{
  background: #FFF;
  padding: 56px 0 0 0;
  .advice {
    width: 1120px;
    height: 80px;
    padding: 50px 40px;
    margin: 0 auto 30px auto;
    border-radius: 6;
    background: #fff;
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);
  }
}
.allfangan{
  overflow: hidden;
  .fangan{
    float: left;
    width: 384px;
    margin: 8px;
    .image{
      width: 100%;
      height: 220px;
      margin-right: 32px;
    }
    .title{
      color: #1D1D1D;
      font-weight: bolder;
      font-size: 16px;
      margin: 15px 0;
    }
    .h2{
      height: 37px;
      color: #999;
    }
  }
}

.bg1{
  background: url("../../assets/znzz/bg1.png") no-repeat;
  background-size: 100% 100%;
  height: 500px;
}


.zhuti{
  width: 1200px;
  margin: 0px auto;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  padding-bottom: 56px;
  color: #1D1D1D;
}
.pad56{
  padding-top: 56px;
}
.width600{
  width: 775px!important;
}

</style>